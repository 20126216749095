import { html } from 'htm/preact';
import * as style from './References.module.css';

import img_del from '../../assets/images/deloitte.png';
import img_taksim from '../../assets/images/taksim_top.png';
import img_tulga from '../../assets/images/tulga.png';
import img_viewcy from '../../assets/images/viewcy.png';
import img_metasar from '../../assets/images/metasar.png';

function References({ referencesTitle }) {

  return html`
        <div class=${style.container} id="references">
          <h3 class=${style.h3}>${referencesTitle}</h3>
          <img src=${img_del} class=${style.invert} alt="Deloitte logo" width="200" height="100" loading="lazy" />
          <img src=${img_tulga} alt="Tulga logo" width="200" height="100" loading="lazy" />
          <img src=${img_taksim} class=${style.invert} alt="Taksim Toplantıları logo" width="200" height="100" loading="lazy" />
          <img src=${img_viewcy} class=${style.invert} alt="Taksim Toplantıları logo" width="200" height="100" loading="lazy" />
          <img src=${img_metasar} class=${style.invert} alt="Tulga logo" width="200" height="100" loading="lazy" />
        </div>
    `;
}

export default References;
