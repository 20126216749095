import { html } from 'htm/preact';
import * as style from './TeamMember.module.css';
import { useContext } from 'preact/hooks';
import { LanguageContext } from '../../contexts/languageContext';

const placeholderImageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII=';

function TeamMember(props) {
  const { t } = useContext(LanguageContext);

  // Destructure the props for clarity
  const { url, name, title, description } = props;

  return html`
  <div class=${style.container}>
      <div class=${style.imageWrapper}><img src=${url ? url: placeholderImageBase64} alt=${name} /></div>
      <div class=${style.contentWrapper}>
        <div class=${style.name}>${name}</div>
        <div class=${style.title}>${title}</div>
        <div class=${style.p}>${description}</div>
      </div>
    </div>
  `;
}

export default TeamMember;
