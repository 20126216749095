import { html } from 'htm/preact';
import * as style from './Team.module.css';
import { useContext } from 'preact/hooks';
import { LanguageContext } from '../../contexts/languageContext';
import SecondaryButton from '../../components/buttons/SecondaryButton';

import image_sd from '../../assets/images/sd.jpg';
import image_md from '../../assets/images/md.jpg';
import image_co from '../../assets/images/co.jpg';

function TeamBlock({ teamTitle, teamDescription, teamCta }) {
  const { t } = useContext(LanguageContext);

  return html`
      <div id="team" class=${style.container}>
        <h3 class=${style.h3}>${teamTitle}</h3>
        <p class=${style.p}>${teamDescription}</p>
        <br />
        <div class=${style.heads}>
          <img src=${image_sd} alt="Sarp" />
          <img src=${image_md} alt="Mert" />
          <img src=${image_co} alt="Canan" />
        </div>
        <${SecondaryButton} href="/team" text="${teamCta}" color="#ffffff" />
      </div>
    `;
}

export default TeamBlock;
