.topBlock {
  max-width: 1024px;
  margin: 50px auto 0;
  text-align: center;
  align-self: flex-start;
  position: relative;
  z-index: 5;
}

.topBlock:before {
  content: '';
  background: linear-gradient(0deg, #eeeeee00, #f5f5f5, #f5f5f5);
  left: 0;
  right: 0;
  top: 0;
  height: 200px;
  position: absolute;
  z-index: -1;
}

.logo {
  width: 100px;
  height: auto;
  margin-top: -60px;
  border: 10px solid #f5f5f5;
  border-radius: 60px;
}

.h3{
  margin-top: 10px;
  margin-bottom: 1.5em;
  font-weight: 900;
}

.author {
  font-style: italic;
  color: #aaa;
  font-size: .9em;
}

.content {
  padding-bottom: 0;
}

.logos {
  margin-bottom: 20px;
}

.logos img {
  max-width: 40%;
  height: auto;
  margin: 10px;
  filter: grayscale(1);
  transition: all .3s;
  transform: scale(1);
  mix-blend-mode: darken;
}

.logos img:hover {
  filter: grayscale(0);
}

.what {
  background: linear-gradient(180deg, #e9ecf3, #f5f8ff);
  padding: 30px;
  clip-path: polygon(0 0, 74% 3%, 100% 0, 100% 100%, 27% 97%, 0 100%);
  margin: 30px 0;
}

.quote {
  padding-top: 1px;
}

@media only screen and (min-width: 1024px) {
  .topBlock:before {
    background: #fff;
  }

  .logo {
    margin: -110px auto 15px;
    border-color: #fff;
    display: block;
  }

  .content > p {
    padding: 0 10%;
  }

  .h3{
    margin-bottom: 1em;
  }

  .logos img {
    max-width: none;
    margin: 10px;
  }

  .what {
    clip-path: polygon(0 0, 74% 10%, 100% 0, 100% 100%, 27% 90%, 0 100%);
    padding: 70px 0;
  }

  .quote h3 {
    margin-top: 0;
  }
}
