import { html } from 'htm/preact';
import * as style from './ContactUs.module.css';
import { useContext, useState, useEffect } from 'preact/hooks';
import { fetchContent } from '../graphqlClient';
import { LanguageContext } from '../contexts/languageContext';
import routes from './Routes';

function getHomepageQuery(locale) {
    return `
      query contactFormEntryQuery {
        contactForm(id: "6LgyHLb6sVg6mCoxsbnuwO", locale: "${locale}") {
          contactTitle
          contactName
          contactEmail
          contactMessage
          contactSubmit
        }
      }
    `;
  }

function ContactUs() {
    const { locale, language } = useContext(LanguageContext);
    const isLocalHost = window.location.hostname === 'localhost';

    const [contactTitle, setContactTitle] = useState('Contact Us');
    const [contactName, setContactName] = useState('Name');
    const [contactEmail, setContactEmail] = useState('Email');
    const [contactMessage, setContactMessage] = useState('Message');
    const [contactSubmit, setContactSubmit] = useState('Submit');



    useEffect(() => {
        const getHomepageContent = async () => {
            try {
                const data = await fetchContent(getHomepageQuery(locale));
                setContactTitle(data.data.contactForm.contactTitle);
                setContactName(data.data.contactForm.contactName);
                setContactEmail(data.data.contactForm.contactEmail);
                setContactMessage(data.data.contactForm.contactMessage);
                setContactSubmit(data.data.contactForm.contactSubmit);
            } catch (error) {
                console.error(error);
            }
        };

        getHomepageContent();
    }, [locale]);

  return html`
  <div>
  <h3 class="textCenter">${contactTitle}</h3>
    <div class=${style.wrapper} id="contact">
      <form class=${style.form} action="https://api.web3forms.com/submit" method="POST">
          <input type="hidden" name="access_key" value="df10f489-865f-4950-9517-de2cfeb4b726" />

          <div class=${style.formGroup}>
              <label for="name">${contactName}</label>
              <input type="text" name="name" id="name" required />
          </div>

          <div class=${style.formGroup}>
              <label for="email">${contactEmail}</label>
              <input type="email" name="email" id="email" required />
          </div>

          <div class=${style.formGroup}>
              <label for="message">${contactMessage}</label>
              <textarea name="message" id="message" required></textarea>
          </div>

          <div class="h-captcha" data-captcha=${!isLocalHost}></div>
          
          <input type="hidden" name="url" value=${window.location.href} />
          <input type="hidden" name="redirect" value="${location.protocol + "//" + location.host + routes[language].success.url}" />

          <div class=${style.formGroup}>
              <button type="submit">${contactSubmit}</button>
          </div>
      </form>
    </div>
    </div>
    `;
}

export default ContactUs;
