.container {
  box-sizing: border-box;
  position: fixed;
  box-shadow: -5px 0 35px rgba(0,0,0,0.3);
  background: rgba(255, 255, 255, 1);
  top: 0;
  height: 100%;
  padding: 20px;
  z-index: 100;
  width: 80%;
  max-width: 300px;
  transition: all .3s;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.container a {
  display: block;
  padding: 10px;
  color: #333;
  font-weight: 700;
  text-decoration: none;
}

.container a.current {
  color: #d31d8d;
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: 99;
  opacity: 0.0;
  transition: opacity 0.3s;
  left: 100%;
  backdrop-filter: blur(20px);
}

.container span {
  display: block;
  padding: 10px;
  color: #333;
  font-weight: 400;
  position: relative;
}

.container span::after {
  content: '';
  position: absolute;
  top: 50%;
  border-top: 1px solid #ddd;
  left: 40%;
  right: 40%;
}
