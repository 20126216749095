.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.logo {
  margin: 0 auto;
  border: 10px solid #fff;
  display: block;
  max-width: 40%;
  border-radius: 50%;
}

.hero {
  height: 100%;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.hero img {
  position: absolute;
  left: -500px;
  right: -500px;
  height: 95%;
  width: auto;
  object-fit: cover;
  margin: auto;
  object-position: bottom;
  bottom: 0;
  transition: filter .5s;
}

.hero img.shadow {
  filter: drop-shadow(0 0 30px rgba(255, 255, 255, .9));
  mix-blend-mode: overlay;
}

.h1 {
  text-align: center;
  color: #333;
  font-size: 2em;
  line-height: 1.35;
  font-weight: 900;
  margin-bottom: .5em;
  font-family: 'Merriweather';
}

.h2 {
  text-align: center;
  color: #555;
  font-weight: 400;
  font-size: 1.20em;
  line-height: 1.2;
}

.content {
  background: #fff;
  padding: 40px 30px;
  transform: translateY(-9%);
  clip-path: polygon(76% 9%, 100% 0, 100% 100%, 0 100%, 0 0);
  text-align: center;
}

.content * {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.heroCta {
  text-decoration: none;
  color: #fff;
  background: linear-gradient(40deg, #ffb52b, #ff5b32);
  font-size: 1.5em;
  padding: 10px 2em 12px;
  display: inline-block;
  border-radius: 60px;
  border: 3px solid #ff5b32;
  margin-top: 15px;
  font-weight: 700;
}

@media only screen and (min-width: 1024px) {
  .content {
    font-size: 25px;
  }

  .content * {
    max-width: 800px;
  }
}

.animation {
  flex: 1;
  background: linear-gradient(140deg, #ffb52b, #ff5b32, #ff32b0, #00e1ff);
  background-size: 600% 600%;
  -webkit-animation: AnimationName 16s ease infinite;
  -moz-animation: AnimationName 16s ease infinite;
  -o-animation: AnimationName 16s ease infinite;
  animation: AnimationName 16s ease infinite;
  will-change: background-position;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}

@-o-keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}