import { html } from 'htm/preact';
import * as style from './Nav.module.css';
import { useContext, useState, useEffect } from 'preact/hooks';
import { LanguageContext } from '../contexts/languageContext';
import { route, Router } from 'preact-iso';
import routes from './Routes';

import LanguageSwitcher from './LanguageSwitcher';
import logo from '../assets/images/dp_logo.png';

function Nav(props) {
  const { t, language } = useContext(LanguageContext);
  const { visible, toggleMenu } = props;

  const navLinks = [
    { href: routes[language].home.url, label: routes[language].home.name },
    { href: routes[language].team.url, label: routes[language].team.name },
    { href: "seperator", label: "" },
    { href: routes[language].development.url, label: routes[language].development.name },
    { href: routes[language].design.url, label: routes[language].design.name },
    { href: routes[language].analytics.url, label: routes[language].analytics.name },
    { href: "seperator", label: "" },
    { href: "#quote", label: t('contactUs') }
  ];

  const determineCurrentClass = (linkHref) => {
	return window.location.pathname === linkHref ? style.current : '';
  };

  document.body.classList.toggle('nav-visible', visible);

  return html`
	<div class=${style.overlay} style="opacity: ${visible ? 1 : 0}; left: ${visible ? 0 : 100}%"
	  onClick=${toggleMenu}
	></div>
	<nav class=${style.container} style="transform: translateX(${visible ? 0 : 105}%); transition-delay: ${visible ? 0.3 : 0}s">
	  ${navLinks.map(link => 
		link.href === "seperator" 
		? html`<span>${link.label}</span>` 
		: html`<a href=${link.href} onClick=${e => {
        e.preventDefault();
        toggleMenu();}} class=${determineCurrentClass(link.href)}>${link.label}</a>`
	  )}
    <${LanguageSwitcher} />
	</nav>
  `;
}

export default Nav;
