import { html } from 'htm/preact';
import * as style from './SecondaryButton.module.css';

function SecondaryButton(props) {
  const { href, text, color } = props;

  const isWhite = color.toLowerCase() === '#ffffff' || color.toLowerCase() === 'white';

  return html`
        <a 
        href=${href} 
        class=${style.button} 
        style="color: ${color}; 
        border-color: ${color};
        --buttonHoverBg: ${color};
        --buttonHoverTextColor: ${isWhite ? 'rgba(0,0,0,0.87)' : '#ffffff'};"
        >${text}</a>
    `;
}

export default SecondaryButton;
