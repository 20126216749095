.container {
    box-sizing: content-box;
    padding: 40px;
    text-align: center;
    color: #fff;
    min-height: calc(80vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container h1 {text-shadow: 0 1px 1px rgba(0,0,0,0.25); margin-bottom: 0;}
.container h2 {color: #fff; text-shadow: 0 1px 1px rgba(0,0,0,0.25); margin-bottom: 30px;}

.animation {
    background: linear-gradient(140deg, #0accc6, #4fe26f, #08bdd4);
    background-size: 600% 600%;
    -webkit-animation: AnimationName 16s ease infinite;
    -moz-animation: AnimationName 16s ease infinite;
    -o-animation: AnimationName 16s ease infinite;
    animation: AnimationName 16s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:17% 0%}
    50%{background-position:84% 100%}
    100%{background-position:17% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:17% 0%}
    50%{background-position:84% 100%}
    100%{background-position:17% 0%}
}
@-o-keyframes AnimationName {
    0%{background-position:17% 0%}
    50%{background-position:84% 100%}
    100%{background-position:17% 0%}
}
@keyframes AnimationName {
    0%{background-position:17% 0%}
    50%{background-position:84% 100%}
    100%{background-position:17% 0%}
}