import { h } from 'preact';
import htm from 'htm';
import { useContext, useEffect, useState } from 'preact/hooks';
import { LanguageProvider } from './contexts/languageContext';
import { route, LocationProvider, Router, useLocation, useRoute } from 'preact-iso';
import routes from './components/Routes';
import Header from './components/Header';
import Footer from './components/Footer';
import 'normalize.css';
import './global.css';
import HomePage from './pages/homepage/HomePage';
import Success from './pages/success/Success';
import TeamPage from './pages/team/TeamPage';
import DevelopmentPage from './pages/development/DevelopmentPage';
import DesignPage from './pages/design/DesignPage';
import AnalyticsPage from './pages/analytics/AnalyticsPage';

import heroHomepageSmallest from './assets/images/hero_smallest.webp'
import heroHomepageSmall from './assets/images/hero_small.webp'
import heroHomepageMedium from './assets/images/hero_medium.webp'
import heroDevelopment from './assets/images/development.webp'
import heroDesign from './assets/images/van.webp'

const html = htm.bind(h);

function App() {
  
  const scrollSmoothlyTo = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    handleRouteChange();
    const handleAnchorClick = (event) => {
      let target = event.target;

      // Traverse up until we find an anchor tag or run out of parent nodes
      while (target && target.tagName !== 'A') {
        target = target.parentElement;
      }

      if (!target) return; // Didn't find an anchor tag

      const hrefValue = target.getAttribute('href');
      const targetUrl = new URL(hrefValue, window.location.origin); // Resolve relative URLs

      if (hrefValue.startsWith('#')) {
        event.preventDefault();
        scrollSmoothlyTo(hrefValue.slice(1));
      } else if (targetUrl.pathname === window.location.pathname) {
        // If the target URL's pathname matches the current URL's pathname
        event.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    document.addEventListener('click', handleAnchorClick);
    return () => document.removeEventListener('click', handleAnchorClick);
  }, []);

  function preloadImage(imagePath) {
    // Check if the requested image is already preloaded.
    const existingLink = document.querySelector(`link[rel="preload"][href="${imagePath}"]`);
    if (existingLink) return;

    // Remove existing preload links.
    const existingLinks = document.querySelectorAll('link[rel="preload"][as="image"]');
    existingLinks.forEach(link => link.parentNode.removeChild(link));

    // Create a new preload link.
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = imagePath;
    link.type = 'image/webp';
    document.head.appendChild(link);
  }


  // Assuming heroHomepageSmallest, heroHomepageSmall, heroHomepageMedium, 
  // heroDevelopment, and heroDesign are defined elsewhere in your code

  // Mapping object for paths and hero images
  const pathToImageMap = {
    '/': () => {
      return window.innerWidth <= 1024 ? heroHomepageSmallest
        : window.innerWidth <= 2000 ? heroHomepageSmall
          : heroHomepageMedium;
    },
    '/tr': () => {
      return window.innerWidth <= 1024 ? heroHomepageSmallest
        : window.innerWidth <= 2000 ? heroHomepageSmall
          : heroHomepageMedium;
    },
    '/web-mobile-app-development': heroDevelopment,
    '/tr/web-mobil-uygulama-gelistirme': heroDevelopment,
    '/ui-ux-design': heroDesign,
    'tr/ui-ux-tasarim': heroDesign,
  };

  const [bodyClass, setBodyClass] = useState("");

  const handleRouteChange = () => {
    const { pathname, hash } = window.location;
    
    if (pathname === "/") {
      setBodyClass("home");
    } else {
      const turkishPath = Object.keys(routes.tr).find(key => routes.tr[key].url === pathname);
      const englishPath = turkishPath ? routes.en[turkishPath].url : null;
      setBodyClass(englishPath ? englishPath.replace("/", "").replaceAll("-", "_") : pathname.replace("/", "").replaceAll("-", "_"));
    }

    const currentRoute = Object.values(routes.en).concat(Object.values(routes.tr)).find(route => route.url === pathname);
    if (currentRoute) {
      if(currentRoute.url === "/" || currentRoute.url === "/tr") {
        document.title = "Digital Peax";
      } else {
        document.title = currentRoute.name + ' - Digital Peax';
      }
    }

    // Determine which image to preload based on the route
    const getImagePath = pathToImageMap[pathname];
    const heroImagePath = typeof getImagePath === 'function' ? getImagePath() : getImagePath;

    // Preload the image if one was found for the route
    if (heroImagePath) {
      preloadImage(heroImagePath);
    }

    // Handle hash change for scrolling or default to top
    if (hash && hash.length > 1) {
      // Delay to ensure the new page's content is in place
      setTimeout(() => scrollSmoothlyTo(hash.slice(1)), 100);
    } else {
      window.scrollTo(0, 0);
    }
  };

  //Change title of the page
  


  return html`
        <div class=${bodyClass}>
            <${LanguageProvider}>
            <${LocationProvider}>
                <${Header} />
                <${Router} onRouteChange=${handleRouteChange} >
                <${HomePage} path="/" />
                <${HomePage} path=${routes.tr.home.url} />
                <${Success} path=${routes.en.success.url} />
                <${Success} path=${routes.tr.success.url} />
                <${TeamPage} path=${routes.en.team.url} />
                <${TeamPage} path=${routes.tr.team.url} />
                <${DevelopmentPage} path=${routes.en.development.url} />
                <${DevelopmentPage} path=${routes.tr.development.url} />
                <${DesignPage} path=${routes.en.design.url} />
                <${DesignPage} path=${routes.tr.design.url} />
                <${AnalyticsPage} path=${routes.en.analytics.url} />
                <${AnalyticsPage} path=${routes.tr.analytics.url} />
                </${Router}>
                <${Footer} />
            </${LocationProvider}>
            </${LanguageProvider}>
        </div>
    `;
}

export default App;
