.container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.container h5 {
  position: relative;
  font-weight: 700;
  color: #333;
  font-size: 1.2em;
  margin-bottom: 15px;
}

.container span {
  position: absolute;
  font-size: 1em;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 30px;
  border: 1px solid #eee;
  color: #333;
  top: 0;
  left: 0;
  transform: translateY(-120%);
}

.container p {
  font-size: 1em;
}

.container img {
  max-width: 80%;
  height: auto;
}

.container a {
  text-decoration: none; 
  color: #0077d9
}

@media only screen and (min-width: 1024px) {
  .container {
    flex-direction: row;
    align-items: center;
    column-gap: 100px;
  }

  .container:nth-child(even) {
    flex-direction: row-reverse;
  }

  .container > img {
    width: 30%;
  }

  .container span {
    top: 50%;
    left: -55px;
    transform: translateY(-50%);
  }
}
