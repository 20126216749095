const routes = {
  en: {
    home: {
      url: '/',
      name: 'Homepage',
    },
    team: 
      {
        url: '/team',
        name: 'Team',
      },
    development: 
      {
        url: '/web-mobile-app-development',
        name: 'Web & Mobile App Development',
      },
    design: 
      {
        url: '/ui-ux-design',
        name: 'UI/UX Design',
      },
    analytics: 
      {
        url: '/google-analytics-4',
        name: 'Google Analytics 4 (GA4)',
      },
    success: 
      {
        url: '/success',
        name: 'Success',
      },
  },
  tr: {
    home: {
      url: '/tr',
      name: 'Ana Sayfa',
    },
    team: 
      {
        url: '/tr/takim',
        name: 'Takım',
      },
    development: 
      {
        url: '/tr/web-mobil-uygulama-gelistirme',
        name: 'Web/Uygulama Geliştirme',
      },
    design: 
      {
        url: '/tr/ui-ux-tasarim',
        name: 'UI/UX Tasarımı',
      },
    analytics: 
      {
        url: '/tr/google-analytics-4',
        name: 'Google Analytics 4 (GA4)',
      },
    success: 
      {
        url: '/tr/talep-gonderildi',
        name: 'Talep Gönderildi',
      },
  }
};

export default routes;
