import { html } from 'htm/preact';
import { useContext, useState, useEffect } from 'preact/hooks';
import { fetchContent } from '../../graphqlClient';
import { LanguageContext } from '../../contexts/languageContext';
import HeroBlock from './Hero';
import TeamBlock from './Team';
import Services from './Services';
import Contact from './Contact';
import References from './References';
import Testimonials from './../../components/Testimonials';

function getHomepageQuery(locale) {
  return `
    query homepageEntryQuery {
      homepage(id: "6ixfWlJcw8Gu2aq8OPRddz", locale: "${locale}") {
        heroTitle
        heroDescription
        mainCta
        servicesTitle
        services
        teamTitle
        teamDescription
        teamCta
        referencesTitle
      }
    }
  `;
}

const servicesContent = [
  {
      "title": "Web/App Development",
      "description": "<p>Developing innovative and user-centric mobile applications.</p><p>Turn your mobile vision into seamless, interactive experiences that captivate users and drive performance.</p>"
  },
  {
      "title": "UI/UX Design",
      "description": "<p>Crafting intuitive and aesthetically pleasing user interfaces.</p><p>Transform your digital platforms into user-friendly, engaging experiences that drive results.</p>"
  },
  {
      "title": "Web/App Analytics",
      "description": "<p>Decode the numbers behind your web traffic.</p><p>From setting up analytics tools to actionable insights, we help you understand and elevate your digital performance.</p>"
  }
];

function HomePage() {
  const { locale } = useContext(LanguageContext);

  const [heroTitle, setHeroTitle] = useState("Design. Develop. Deliver.");
  const [heroDescription, setHeroDescription] = useState("Join us on a journey from pixel-perfect designs to functional digital products");
  const [mainCta , setMainCta] = useState("Discover");
  const [ser, setSer] = useState("What do we do");
  const [serContent, setSerContent] = useState(servicesContent);
  const [teamTitle, setTeamTitle] = useState("Who we are");
  const [teamDescription, setTeamDescription] = useState("We're a team that seamlessly blend creativity and technology to craft impactful digital solutions.");
  const [teamCta, setTeamCta] = useState("Meet the Team");
  const [referencesTitle, setReferencesTitle] = useState("References");

  useEffect(() => {
    const getHomepageContent = async () => {
    try {
      const data = await fetchContent(getHomepageQuery(locale));
      setHeroTitle(data.data.homepage.heroTitle);
      setHeroDescription(data.data.homepage.heroDescription);
      setMainCta(data.data.homepage.mainCta);
      setSer(data.data.homepage.servicesTitle);
      setSerContent(data.data.homepage.services);
      setTeamTitle(data.data.homepage.teamTitle);
      setTeamDescription(data.data.homepage.teamDescription);
      setTeamCta(data.data.homepage.teamCta);
      setReferencesTitle(data.data.homepage.referencesTitle);
    } catch (error) {
      console.error(error);
    }
    };

    getHomepageContent();
  }, [locale]);

  return html`
        <${HeroBlock} heroTitle=${heroTitle} heroDescription=${heroDescription} mainCta=${mainCta} />
        <${Services} servicesTitle=${ser} servicesContent=${serContent} />
        <${TeamBlock} teamTitle=${teamTitle} teamDescription=${teamDescription} teamCta=${teamCta} />
        <${Testimonials} isWhite="true" />
        <${References} referencesTitle=${referencesTitle} />
        <${Contact} />
    `;
}

export default HomePage;
