.animation {
  display: flex;
  width: 100%;
}

.container > div {
  min-height: 100vh;
  display: flex;
  flex-direction: column-reverse;
  max-width: 1300px;
  margin: 0 auto;
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
}

.content {
  background: #fff;
  color: rgba(0,0,0,0.87);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: center;
  padding: 90px 30px 80px;
  clip-path: polygon(76% 40px, 100% 0, 100% 100%, 0 100%, 0 0);
  margin-top: -40px;
  position: relative;
  z-index: 3;
}

.content a {
  margin-top: 30px;
}

.h1 {
  font-size: 2em;
  font-family: 'Merriweather', serif;
  margin: 0 auto;
  margin-bottom: 15px;
  font-weight: 900;
}

.h2 {
  font-size: 1.2em;
  font-weight: 400;
  margin: 0 auto;
  padding: 0 20px;
}

.hero {
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.hero img {
  height: 90%;
  width: auto;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  object-position: bottom;
  transition: filter .5s;
}

.hero img.shadow {
  filter: drop-shadow(0 0 30px rgba(255, 255, 255, .9));
  mix-blend-mode: overlay;
}

.animation {
  flex: 1;
  background: linear-gradient(140deg, #008cff, #00b7ff, #0084ff, #8b32ff, #00e1ff);
  background-size: 600% 600%;
  -webkit-animation: AnimationName 16s ease infinite;
  -moz-animation: AnimationName 16s ease infinite;
  -o-animation: AnimationName 16s ease infinite;
  animation: AnimationName 16s ease infinite;
}

@media only screen and (min-width: 1024px) {
  .animation {
    clip-path: polygon(100% 0, 100% calc(100% - 80px), 50% 100%, 0 calc(100% - 80px), 0 0);
  }

  .container > div {
    flex-direction: row;
    min-height: 600px;
    padding: 0 100px;
  }

  .content {
    background: none;
    color: #fff;
    text-align: left;
    padding: 0;
    flex: 1;
  }

  .hero {
    overflow: visible;
  }

  .hero img {
    height: 95%;
  }

  .h1 {
    font-size: 3em;
    margin: 25px 0 15px;
  }

  .h2 {
    font-size: 2em;
    padding: 0;
    margin: 0;
  }
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}

@-o-keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}
