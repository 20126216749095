import { h, render } from 'preact';
import App from './src/App';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-PSQT6P2H'
}

TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById("root");
render(h(App), rootElement);
