import { html } from 'htm/preact';
import * as style from './Services.module.css';
import ServiceCard from './ServiceCard';
import { useContext, useEffect, useRef } from 'preact/hooks';
import routes from '../../components/Routes';
import { LanguageContext } from '../../contexts/languageContext';

import img_app from '../../assets/images/app.webp';
import img_design from '../../assets/images/design.webp';
import img_analytics from '../../assets/images/analytics.webp';
import img_testing from '../../assets/images/testing.webp';

function Services({ servicesTitle, servicesContent }) {
  const { language } = useContext(LanguageContext);
  const CARD_PADDING = -100;

  const gradients = [
    ['#4d5ce2', '#00e1ff'],
    ['#ff5b32', '#ff32b0'],
    ['#ee2fc3', '#b032ff']
    //['#51e972', '#00d9d1']
  ];

  const changeBackground = () => {
    const container = document.querySelector(`.${style.container}`);
    const wrapper = document.querySelector(`.${style.cardsWrapper}`);
    const cards = Array.from(document.querySelectorAll(`.${style.cardsWrapper} > div`));

    const totalScroll = wrapper.scrollWidth - wrapper.offsetWidth;
    const adjustedTotalScroll = totalScroll - (CARD_PADDING * (cards.length - 1));
    const percentScrolled = wrapper.scrollLeft / adjustedTotalScroll;
    const cardIndex = Math.min(Math.floor(percentScrolled * cards.length), cards.length - 2);

    const startColors = gradients[cardIndex];
    const endColors = gradients[cardIndex + 1];

    const colorPosition = (percentScrolled - (cardIndex / cards.length)) * cards.length;

    const interpolatedStart = interpolateColor(startColors[0], endColors[0], colorPosition);
    const interpolatedEnd = interpolateColor(startColors[1], endColors[1], colorPosition);

    container.style.background = `linear-gradient(0deg, ${interpolatedStart}, ${interpolatedEnd})`;
  }

  let currentStartColor = gradients[0][0]; // start with the initial gradient's start color
  let currentEndColor = gradients[0][1];   // start with the initial gradient's end color

  const animateGradient = (targetStartColor, targetEndColor, duration) => {
    let startTime;
    const container = document.querySelector(`.${style.container}`);
    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) / duration;
      const interpolatedStart = interpolateColor(currentStartColor, targetStartColor, progress);
      const interpolatedEnd = interpolateColor(currentEndColor, targetEndColor, progress);
      container.style.background = `linear-gradient(0deg, ${interpolatedStart}, ${interpolatedEnd})`;
      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        currentStartColor = targetStartColor;
        currentEndColor = targetEndColor;
      }
    };
    requestAnimationFrame(animate);
  };

  const changeBackgroundBasedOnCardIndex = (index) => {
    const targetStartColor = gradients[index][0];
    const targetEndColor = gradients[index][1];
    animateGradient(targetStartColor, targetEndColor, 250); // 500ms duration, adjust as needed
  }

  const scrollable = useRef();

  useEffect(() => {
    const scrollElement = scrollable.current;
    const width = scrollElement.scrollWidth;
    let prevScroll = null;
    let scroll = 0;

    const handleScroll = (e) => {
      if (!scrollElement || !isOverflowX(scrollElement) || isUserUsingMobile()) return;

      scrollElement.style.scrollSnapType = "none";
      scroll += e.deltaY;

      if (width < scroll) {
        scroll = scrollElement.scrollWidth;
      } else if (scroll < 0) {
        scroll = 0;
      }

      if (prevScroll !== scroll) {
        prevScroll = scroll;
        scrollElement.scrollLeft = scroll;
        e.preventDefault();
      }
    };

    const cards = Array.from(document.querySelectorAll(`.${style.cardsWrapper} > div`));

    cards.forEach((card, index) => {
      card.addEventListener('mouseenter', () => {
        changeBackgroundBasedOnCardIndex(index);
      }, {passive: true});
    });


    if (scrollElement) {
      scrollElement.addEventListener('wheel', handleScroll, {passive: true});
      scrollElement.addEventListener('scroll', changeBackground, {passive: true});
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('wheel', handleScroll);
        scrollElement.removeEventListener('scroll', changeBackground);

        cards.forEach((card) => {
          card.removeEventListener('mouseenter', changeBackgroundBasedOnCardIndex);
        });
      }
    }
  }, []);

  return html`
        <div id="services" class=${style.container}>
          <h3 class=${style.h3}>${servicesTitle}</h3>
          <div ref=${scrollable} class=${style.cardsWrapper}>

            <${ServiceCard} 
            title=${servicesContent[0].title}
            desc=${servicesContent[0].description}
            cta=${servicesContent[0].cta}
            ctaUrl=${routes[language].development.url}
            img=${img_app}
            color="#299aef"
            borderColor="#6ceeff" />

            <${ServiceCard} 
            title=${servicesContent[1].title}
            desc=${servicesContent[1].description}
            cta=${servicesContent[1].cta}
            ctaUrl=${routes[language].design.url}
            img=${img_design}
            color="#e9594e"
            borderColor="#ffbb88" />

            <${ServiceCard} 
            title=${servicesContent[2].title}
            desc=${servicesContent[2].description}
            cta=${servicesContent[2].cta}
            ctaUrl=${routes[language].analytics.url}
            img=${img_analytics}
            color="#cf30e1"
            borderColor="#ff6cd2" />
            
            <!--
            <${ServiceCard} 
            title="A/B Testing"
            desc=${html`
            <p>Optimize with confidence.</p>
            <p>Through controlled experiments, 
            we help you determine which design or content variation drives the best results.
            </p>
            `}
            cta="Optimize Now"
            ctaUrl="/design"
            img=${img_testing}
            color="#13a371"
            borderColor="#6cff71" />
            -->

          </div>
        </div>
    `;
}

function interpolateColor(color1, color2, position) {
  function hexToRgb(hex) {
      let bigint = parseInt(hex.slice(1), 16);
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  }

  function componentToHex(c) {
      let hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
  }

  function rgbToHex(r, g, b) {
      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  let [r1, g1, b1] = hexToRgb(color1);
  let [r2, g2, b2] = hexToRgb(color2);

  // Calculate the interpolated RGB values
  let r = Math.round(r1 + (r2 - r1) * position);
  let g = Math.round(g1 + (g2 - g1) * position);
  let b = Math.round(b1 + (b2 - b1) * position);

  return rgbToHex(r, g, b);
}

const isUserUsingMobile = () => {

  // User agent string method
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  // Screen resolution method
  if (!isMobile) {
    let screenWidth = window.screen.width;
    let screenHeight = window.screen.height;
    isMobile = (screenWidth < 768 || screenHeight < 768);
  }

  // Touch events method
  if (!isMobile) {
    isMobile = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
  }

  // CSS media queries method
  if (!isMobile) {
    let bodyElement = document.getElementsByTagName('body')[0];
    isMobile = window.getComputedStyle(bodyElement).getPropertyValue('content').indexOf('mobile') !== -1;
  }

  return isMobile
}

function isOverflowX(element) {
  return element.scrollWidth != Math.max(element.offsetWidth, element.clientWidth)
}

export default Services;
