.header {
  position: fixed;
  width: 100%;
  z-index: 99;
  transition: .3s;
  top: 0; left: 0;
  will-change: box-shadow, background-color, top;
}

.header > div {
  padding: 10px;
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
}

.logoWrapper {
  display: flex;
  align-items: center;
}

.logoWrapper {
  text-decoration: none;
  font-size: 1.4em;
  font-weight: 700;
  color: #fff;
  letter-spacing: .5;
}

.logoBg {
  background: #fff;
  width: 40px;
  margin-right: 10px;
  border-radius: 50%;
}

.logo {
  width: 40px;
  display: block;
  border-radius: 50%;
  box-sizing: initial;
  scale: .85;
}

.menu{
  background: none;
  border: none;
  color: #fff;
  display: inline-block;
  align-self: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
