import { html } from 'htm/preact';
import * as style from './GradientHeader.module.css';

function GradientHeader() {

  return html`
        <div class=${style.container}>
          <div class=${style.animation}></div>
        </div>
        `;
}

export default GradientHeader;
