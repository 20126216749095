.testimonial {
  clip-path: polygon(0 0, 50% 20px, 100% 0, 100% 100%, 50% calc(100% - 20px), 0 100%);
  font-family: Merriweather, serif;
  font-weight: 300;
  font-size: 1em;
  background-color: #222;
  margin: .8em 0 1.4em;
  padding: 50px 0;
  color: #fff;
  background: linear-gradient(45deg, #222222ff, #222222d7, #222222ff), url(../assets/images/test_gray_small.jpg);
  background: linear-gradient(45deg, #222222ff, #222222d7, #222222ff), url(../assets/images/test_gray_small.webp);
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial_white {
  clip-path: none;
  background: linear-gradient(45deg, #ffffffff, #eeeeeed7, #ffffffff), url(../assets/images/test_gray.jpg);
  background: linear-gradient(45deg, #ffffffff, #eeeeeed7, #ffffffff), url(../assets/images/test_gray.webp);
  color: #666;
  margin: 0;
}

.testimonial .content {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  text-align: center;
  max-width: 900px;
  column-gap: 30px;
  align-items: center;
  line-height: 1.5;
}

.testimonial .content img {
  border-radius: 50%;
  filter: grayscale(1);
}

.testimonial q {
  display: block;
  margin-bottom: 10px;
}

.testimonial .content {
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.testimonial .content.fade-in {
  opacity: 1;
}

@media only screen and (min-width: 1024px) {

.testimonial {
  clip-path: none;
  background: linear-gradient(45deg, #ffffffff, #eeeeeed7, #ffffffff), url(../assets/images/test_gray.jpg);
  background: linear-gradient(45deg, #ffffffff, #eeeeeed7, #ffffffff), url(../assets/images/test_gray.webp);
  background-repeat: no-repeat;
  background-size: cover;
  color: #333;
  height: 150px;
}

.testimonial .author {
  color: #666;
}

.testimonial .content {
  flex-direction: row;
  text-align: left;
}

}
