.topBlock {
  max-width: 1024px;
  margin: 50px auto 0;
  text-align: center;
  align-self: flex-start;
  position: relative;
  z-index: 5;
}

.topBlock:before {
  content: '';
  background: linear-gradient(0deg, #eeeeee00, #f5f5f5, #f5f5f5);
  left: 0;
  right: 0;
  top: 0;
  height: 200px;
  position: absolute;
  z-index: -1;
}

.logo {
  width: 100px;
  height: auto;
  margin-top: -60px;
  border: 10px solid #f5f5f5;
  border-radius: 60px;
}

.h3{
  margin-top: 10px;
  margin-bottom: 1.5em;
  font-weight: 900;
}

.content {
  padding-bottom: 0;
}

.quote {
  padding-top: 1px;
}

.list {
  list-style: none;
  max-width: 600px;
  text-align: left;
  margin: 30px auto;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 0;
  overflow: hidden;
}

.list li {
  padding: 20px 20px;
  display: flex;
  align-items: center;
}

.list li:nth-child(even) {
  background: #f9f9f9;
}

.list li h5 {
  display: block;
  font-weight: 700;
  margin: 0 0 10px 0;
  font-size: 1em;
}

.list img { width: 40px; height: auto; margin-right: 20px; }

.whatis {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.whatis p {
  text-align: left;
}

.ga4 {
  max-width: 30%;
  margin: 10px 0 20px;
  display: inline-block;
}

.charts {
  filter: contrast(1.25);
  max-width: 80%;
}

.how_list {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: left;
  font-size: 1em;
  margin-bottom: 30px;
}

.how_list b {
  font-weight: 700;
  display: inline-block;
  margin-bottom: 5px;
}

.how_list li {
  list-style: none;
  margin: 20px 0 0;
  position: relative;
}

.how_list li:before {
  content: '';
  position: absolute;
  background: #ccc;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  left: -20px;
  top: 6px;
}

.how_list ul {
  padding: 0 30px 0 50px;
  background: #f5f5f5;
  margin: 0 -30px;
}

.how_list ul:first-child {
  padding-top: 10px;
}

.how_list ul:last-child {
  padding-bottom: 30px;
}

@media only screen and (min-width: 1024px) {
  .topBlock:before {
    background: #fff;
  }

  .logo {
    margin: -110px auto 15px;
    border-color: #fff;
    display: block;
  }

  .content > p {
    padding: 0 10%;
  }

  .h3{
    margin-bottom: 1em;
  }

  .quote h3 {
    margin-top: 0;
  }

  .whatis {
    flex-direction: row;
    text-align: left;
    column-gap: 50px;
    width: 75%;
    margin: 100px auto 50px;
  }

  .ga4 {
    max-width: 220px;
    margin-top: 70px;
  }

  .how {
    margin: 0 auto 30px;
    flex-direction: row-reverse;
  }

  .charts {
    max-width: 400px;
    margin: 0;
  }

  .how_list {
    flex-direction: row;
    column-gap: 30px;
    max-width: 75%;
    margin-bottom: 75px;
  }

  .how_list ul, .how_list ul:first-child, .how_list ul:last-child {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 20px;
    padding: 0px 20px;
    margin: 0;
    flex: 1;
    padding-left: 40px;
    padding-bottom: 20px;
  }
}
