import { html } from 'htm/preact';
import * as style from './HeroButton.module.css';

function HeroButton(props) {
  const { href, text } = props;

  return html`
        <a
        href=${href} 
        class=${style.button} 
        >${text}</a>
    `;
}

export default HeroButton;
