.container {
  padding: 70px 0 0;
  background: linear-gradient(0deg, #4d5ce2, #00e1ff);
  clip-path: polygon(50% 50px, 100% 0, 100% 100%, 0 100%, 0 0);
  text-align: center;
  position: relative;
}

.h3 {
  color: #fff;
  margin-bottom: 30px;
  position: relative;
  z-index: 9;
}

.cardsWrapper{
  padding: 25px 25px 100px 20px;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  align-items: stretch;
  position: relative;
  z-index: 9;
  grid-auto-flow: column;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  scroll-behavior: smooth;
  gap: 20px;
  max-width: 100vw;
  box-sizing: border-box;
}

.cardsWrapper::-webkit-scrollbar{
  display: none;
}

.cardsWrapper p {
  white-space: initial;
}

.cardsWrapper a {
  font-size: 1.3em;
}

@media only screen and (min-width: 1024px) {
  .container {
    padding-bottom: 140px;
  }
  .cardsWrapper {
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 0;
  }
  .h3 {
    margin: 80px auto;
  }

  .cardsWrapper > div {
    transition: all .3s;
  }

  .cardsWrapper:hover > div:not(:hover) {
    filter: grayscale(1);
  }
}
