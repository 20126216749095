const defaultContent = {
  "title": "Following the masters",
  "experience": "Digital Peax delivers stunning and engaging design solutions that make your web and mobile apps stand out.",
  "how_we_work": {
    "title": "How do we work?",
    "intro": "From vision to design, our process has well-defined stages, ensuring quality and creativity.",
    "stages": [
      {
        "title": "User Research",
        "description": "Conduct in-depth user research to understand your target audience and their needs."
      },
      {
        "title": "Information Architecture",
        "description": "Organize content and navigation for optimal user flow and information access."
      },
      {
        "title": "User Experience (UX) Design",
        "description": "Create wireframes to outline the structure and layout of your application or website."
      },
      {
        "title": "User Interface (UI) Design",
        "description": "Design the visual elements, layout, and aesthetics of your application or website to create an engaging and visually appealing user interface."
      },
      {
        "title": "Prototyping",
        "description": "Build interactive prototypes to test and refine the user experience."
      },
      {
        "title": "Usability Testing",
        "description": "Conduct usability tests to identify and resolve usability issues in your design."
      },
      {
        "title": "Accessibility Compliance",
        "description": "Ensure your designs are accessible to all users, including those with disabilities."
      },
      {
        "title": "Final Design",
        "description": "Create final designs, deliver assets (icons, images, artboards) and a detailed style guide."
      }
    ],
    "goal": "Our goal is to transform your vision into exceptional design, step by step."
  },
  "cta": "Get Quote",
  "our_works": {
    "title": "Our works",
    "description": "These are some of our projects that showcase our proficiency in impactful design."
  }
}

export default defaultContent;
