.container {
    background: linear-gradient(180deg, #222, #222222d7), url(../../assets/images/team_small_min.webp) no-repeat center;
    background-size: cover;
    clip-path: polygon(26% 30px, 100% 0, 100% 100%, 0 100%, 0 0);
    padding: 50px 0 120px;
    min-height: 80vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -31px;
}

.heads {
  margin-bottom: 30px;
  margin-bottom: 50px;
  position: relative;
}

.heads img {
  width: 30vw;
  height: 30vw;
  max-width: 180px;
  max-height: 180px;
  object-fit: cover;
  border-radius: 50%;
  filter: grayscale(1);
  border: 5px solid #fff;
  margin: 0px 12px;
  display: inline-block;
}

.h3 {
  color: #ffffff;
  padding: 0 50px;
}

.p {
  color: #ffffff;
  padding: 0 50px;
}

.teamMembers {
  margin: 40px 0;
}

@media only screen and (min-width: 1024px) {
  .container {
    background: linear-gradient(180deg, #222, #222222d7), url(../../assets/images/team.jpg) no-repeat center;
    background-size: cover;
    min-height: auto;
  }
}
