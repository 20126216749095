:root {
  --depth: 10px;
}

.container {
  text-align: center;
  margin: 0 auto;
  padding: 0 30px 0;
  vertical-align: top;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  background-color: #ff5b32;
  transform: skewY(-3deg);
  margin-top: 5px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 0 15px;
  height: 200px;
  vertical-align: top;
}

.container::before {
  background: linear-gradient(180deg, rgba(255,255,255,.4), rgba(0,0,0,0.2));
  mix-blend-mode: overlay;
  inset: 0;
  position: absolute;
  content: '';
  z-index: -1;
}

.container:nth-child(5n+1) {
  background-color: #ff972b;
}
.container:nth-child(5n+2) {
  background-color: #ff5b32;
}
.container:nth-child(5n+3) {
  background-color: #0fccce;
}
.container:nth-child(5n+4) {
  background-color: #fe3d76;
}
.container:nth-child(5n+5) {
  background-color: #7d2bff;
}

.imageWrapper {
  padding: 5px;
  width: 100px;
  height: 100px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
}

.contentWrapper {
  text-align: start;
  color: #fff;
}

.imageWrapper:after {
  content: '';
  position: absolute;
  background: #fff;
  opacity: 1;
  background-blend-mode: overlay;
  inset: 0;
  border-radius: 50%;
  z-index: -1;
}

.container img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  display: block;
}

.name {
  font-size: 1.25em;
  font-weight: 700;
}

.title {
  font-style: italic;
  font-size: .8em;
  font-weight: 300;
  margin-top: 5px;
  font-family: 'Merriweather';
  opacity: .7;
}

.p {
  margin-top: 15px;
  font-size: 1em;
  font-weight: 300;
}

@media only screen and (min-width: 1024px) {
  .container {
    width: 45%;
    margin: 5px;
    display: inline-flex;
    transform: skewY(0deg);
    border-radius: 15px;
    overflow: hidden;
  }
}
