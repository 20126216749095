.topBlock {
  max-width: 1024px;
  margin: 50px auto 0;
  text-align: center;
  align-self: flex-start;
  position: relative;
  z-index: 5;
}

.topBlock:before {
  content: '';
  background: linear-gradient(0deg, #eeeeee00, #f5f5f5, #f5f5f5);
  left: 0;
  right: 0;
  top: 0;
  height: 200px;
  position: absolute;
  z-index: -1;
}

.logo {
  width: 100px;
  height: auto;
  margin-top: -60px;
  border: 10px solid #f5f5f5;
  border-radius: 60px;
}

.h3{
  margin-top: 10px;
  margin-bottom: 1.5em;
  font-weight: 900;
}

.how > div {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.how h5 {
  position: relative;
  font-weight: 700;
  color: #333;
  font-size: 1.2em;
  margin-bottom: 15px;
}

.how span {
  position: absolute;
  font-size: 1em;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 30px;
  border: 1px solid #eee;
  color: #333;
  top: 0;
  left: 0;
  transform: translateY(-120%);
}

.how p {
  font-size: 1em;
}

.how img {
  max-width: 80%;
  height: auto;
}

.how a {
  text-decoration: none; 
  color: #0077d9
}

.author {
  font-style: italic;
  color: #aaa;
  font-size: .9em;
}

.content {
  padding-bottom: 0;
}

.logos {
  margin-bottom: 20px;
}

.logos img {
  max-width: 40%;
  height: auto;
  margin: 10px;
  filter: grayscale(1);
  transition: all .3s;
  transform: scale(1);
  mix-blend-mode: darken;
}

.logos img:hover {
  filter: grayscale(0);
}

.what {
  background: linear-gradient(180deg, #e9ecf3, #f5f8ff);
  padding: 30px;
  clip-path: polygon(0 0, 74% 3%, 100% 0, 100% 100%, 27% 97%, 0 100%);
  margin: 30px 0;
}

.quote {
  padding-top: 1px;
}

.list {
  list-style: none;
  max-width: 600px;
  text-align: left;
  margin: 30px auto;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 0;
  overflow: hidden;
}

.list li {
  padding: 20px 20px;
  display: flex;
  align-items: center;
}

.list li:nth-child(even) {
  background: #f9f9f9;
}

.list li h5 {
  display: block;
  font-weight: 700;
  margin: 0 0 10px 0;
  font-size: 1em;
}

.list img { width: 40px; height: auto; margin-right: 20px; }

.brush {
  width: 100%;
  height: auto;
  max-width: 300px;
  margin: 40px auto 20px;
}

.portfolio_box {
  border: 1px solid #eee;
  border-radius: 20px;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 20px;
  box-shadow: 0 0 40px rgba(0,0,0,0.1);
}

.portfolio_box img {
  max-width: 100%;
  height: auto;
  display: block;
}

@media only screen and (min-width: 1024px) {
  .topBlock:before {
    background: #fff;
  }

  .logo {
    margin: -110px auto 15px;
    border-color: #fff;
    display: block;
  }

  .content > p {
    padding: 0 10%;
  }

  .h3{
    margin-bottom: 1em;
  }

  .how {
    max-width: 1000px;
    margin: 0 auto;
  }

  .how > div {
    flex-direction: row;
    align-items: center;
    column-gap: 100px;
  }

  .how > div:nth-child(even) {
    flex-direction: row-reverse;
  }

  .how > div > img {
    width: 30%;
  }

  .how span {
    top: 50%;
    left: -55px;
    transform: translateY(-50%);
  }

  .logos img {
    max-width: none;
    margin: 10px;
  }

  .what {
    clip-path: polygon(0 0, 74% 10%, 100% 0, 100% 100%, 27% 90%, 0 100%);
    padding: 70px 0;
  }

  .quote h3 {
    margin-top: 0;
  }

  .brush {
    max-width: 400px;
    margin: 30px auto 20px;
  }

  .portfolio_box {
    max-width: 40%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .portfolio_wrapper {
    margin-top: 40px;
    margin-bottom: 50px;
  }
}
