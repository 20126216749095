.footer {
  background: #222;
  color: rgba(255, 255, 255, .77);
  text-align: left;
  align-self: end;
  border-top: 5px solid #ff8e2e;
}

.content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.links {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  justify-content: start;
}

.footer .title {
  font-size: 1.1em;
  margin: 0 0 20px;
  font-weight: 700;
  position: relative;
}

.footer .title::before {
  content: '';
  height: 3px;
  position: absolute;
  background-color: #ff8e2e;
  left: 0;
  border-radius: 5px;
  bottom: -5px;
  width: 35px;
}

.links a {
  color: rgba(255, 255, 255, .77);
  font-size: .9em;
  text-decoration: none;
}

.links a:hover {
  color: #fff
}

.logo {
  margin: 0 auto 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  justify-content: center;
  color: rgba(255, 255, 255, .77);
  font-size: .8em;
  background: rgba(0,0,0,0.2);
  padding: 20px 0;
}

.logo img {
  width: 40px;
  margin-right: 10px;
  animation: rotate;
  animation-duration: 180s;
  animation-iteration-count: infinite;
}

.footer .logo p {
  margin: 0;
  font-size: 1.2em;
}

.footer p {
  font-size: .9em;
  margin: 10px 0;
}
.footer p:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1024px) {
  .content {
    flex-direction: row-reverse;
    column-gap: 50px;
  }
  .content > div:last-child {
    flex: 2;
  }
  .content > div:first-child {
    flex: 1;
  }
}

@keyframes rotate {
  0%{transform: rotate(0deg);}
  100%{transform: rotate(360deg);}
}
