.wrapper {
  padding: 0 30px 50px;
}

.form {
  max-width: 500px;
  margin: 0 auto;
}

/* Form Group */
.formGroup {
  margin-bottom: 20px;
}

/* Label styling */
.formGroup label {
  display: block;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

/* Input and Textarea styling */
.formGroup input,
.formGroup textarea {
  width: 100%;
  min-height: 40px;
  padding: 0px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.formGroup textarea {
  min-height: 150px;
  resize: vertical;
  padding: 10px;
  /* Allows user to resize textarea vertically */
}

/* Focus effect */
.formGroup input:focus,
.formGroup textarea:focus {
  border-color: #007BFF;
  /* A blue color for focus */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

/* Submit Button styling */
.formGroup button {
  text-decoration: none;
  color: #fff;
  background: linear-gradient(40deg, #ffb52b, #ff5b32);
  font-size: 1.5em;
  padding: 10px 20px 12px;
  display: inline-block;
  border-radius: 50px;
  border: 0px solid #ff5b32;
  font-weight: 700;
  width: 100%;
  margin-top: 20px;
}

.formGroup button:hover {
  background-color: #0056b3;
  /* A darker shade of blue */
}