.container {
  background: #222;
  padding: 80px 30px 100px;
  text-align: center;
}

.container img {
  width: 40%;
  max-width: 200px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 5px 5%;
  mix-blend-mode: lighten;
  filter: grayscale(1);
}

img.invert {
  filter: invert(1) grayscale(1);
}

.h3 {
  color: #fff;
}

@media only screen and (min-width: 1024px) {
  .container img {
    max-width: 150px;
    margin: 0 50px
  }
}
