import { html } from 'htm/preact';
import { useContext, useState, useEffect } from 'preact/hooks';
import { fetchContent } from '../../graphqlClient';
import { LanguageContext } from '../../contexts/languageContext';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import TeamMember from './TeamMember';
import * as style from './TeamPage.module.css';
import GradientHeader from '../../components/GradientHeader';
import ContactUs from '../../components/ContactUs';

import image_hero_fallback from '../../assets/images/team_color.jpeg';
import image_hero from '../../assets/images/team_color.webp';

import image_sd from '../../assets/images/sd.jpg';
import image_md from '../../assets/images/md.jpg';
import image_co from '../../assets/images/co.jpg';
import image_jd from '../../assets/images/jd.jpg';
import image_mr from '../../assets/images/mr.jpg';
import image_eli from '../../assets/images/eli.webp';

import image_arrow_down from '../../assets/images/arrow_down.webp';

import header_img from '../../assets/images/team.jpg';
import header_img_small from '../../assets/images/team_small_min.webp';

function getTeamQuery(locale) {
  return `
    query teamEntryQuery {
      team(id: "4eG1L0MGphTdXdf1m2RCDG", locale: "${locale}") {
        heroTitle
        heroDescription
        teamContent
    teamPhotosCollection {
      items {
        title
        url
      }
    }
      }
    }
  `;
}

const teamContentDefault = [
  {
      "name": "Sarp",
      "title": "Co-founder",
      "description": "Digital marketer, designer & developer"
  },
  {
      "name": "Mert",
      "title": "Co-founder",
      "description": "Architect, tech guru"
  },
  {
      "name": "Canan",
      "title": "Business Development",
      "description": "Growth Strategist"
  },
  {
      "name": "Juan",
      "title": "Sr. Software Developer",
      "description": "Code Maestro"
  },
  {
      "name": "Michal",
      "title": "Software Developer",
      "description": "Backend Whiz"
  },
  {
      "name": "Ellie",
      "title": "Designer",
      "description": "Magic brush"
  }
];

function TeamPage() {
  const { locale } = useContext(LanguageContext);

  const [heroTitle, setHeroTitle] = useState("Design. Develop. Deliver.");
  const [heroDescription, setHeroDescription] = useState("Join us on a journey from pixel-perfect designs to functional digital products");
  const [teamContent, setTeamContent] = useState(teamContentDefault);
  const [teamPhotos, setTeamPhotos] = useState([]);

  useEffect(() => {
    const getTeamContent = async () => {
    try {
      const data = await fetchContent(getTeamQuery(locale));
      setHeroTitle(data.data.team.heroTitle);
      setHeroDescription(data.data.team.heroDescription);
      setTeamContent(data.data.team.teamContent);
      setTeamPhotos(data.data.team.teamPhotosCollection.items);
    } catch (error) {
      console.error(error);
    }
    };

    getTeamContent();
  }, [locale]);

  return html`
        <div class=${style.hero_wrapper}>
        <picture>
          <source srcset="${image_hero}" type="image/webp" />
          <img src=${image_hero_fallback} alt="Team" class=${style.hero}  width="800" height="800" />
        </picture>
            <div class=${style.hero_content}>
              <h1>${heroTitle}</h1>
              <p>${heroDescription}</p>
              <img src=${image_arrow_down} class=${style.bounce} width="300" height="300" />
            </div>
        </div>
        <div class=${style.teamWrapper}>
        ${teamContent.map((member, index) => {
          const memberPhoto = teamPhotos.find(photo => photo.title === member.name);
          const memberPhotoUrl = memberPhoto ? memberPhoto.url : '';
          return html`
            <${TeamMember}
              url=${memberPhotoUrl}
              name=${member.name}
              title=${member.title}
              description=${member.description}
            />
          `;
        })}
        </div>
        <div style="background: #fff" id="quote">
            <div>
              <${ContactUs} />
            </div>
        </div>
    `;
}

export default TeamPage;
