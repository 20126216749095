.container {
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.container h1 {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  margin-bottom: 0;
  position: relative;
  z-index: 5;
  font-family: 'Merriweather';
  font-size: 2em;
  line-height: 1.4;
  letter-spacing: 1.3;
}

.container h2 {
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  margin-bottom: 30px;
  position: relative;
  z-index: 5;
  font-size: 1.5em;
  font-weight: 400;
}
.animation::after {
  background: linear-gradient(140deg, #ffb52b, #ff5b32, #ff32b0, #7700ff);
  background-size: 600% 600%;
  -webkit-animation: AnimationName 16s ease infinite;
  -moz-animation: AnimationName 16s ease infinite;
  -o-animation: AnimationName 16s ease infinite;
  animation: AnimationName 16s ease infinite;
  position: absolute;
  z-index: 1;
  inset: 0;
  content: '';
  opacity: .8;
}

.animation {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  height: 60px;
  width: 100%;
  z-index: 1;
  background-color: #fff;
}

@media only screen and (min-width: 1024px) {
  .animation {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1400px auto;
  }
  .animation::before {
    background: linear-gradient(90deg, #fff, #fff, rgba(255,255,255,.6), #fff, #fff);
  }
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}

@-o-keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 17% 0%
  }

  50% {
    background-position: 84% 100%
  }

  100% {
    background-position: 17% 0%
  }
}
