const defaultContent = {
  "title": "The art of analysis",
  "experience": "With our long background with Google Analytics, we’re confident we can make a significant impact on your business.",
  "belief": "We believe in continuous improvement and this process begins with understanding.",
  "how_we_work": {
    "title": "How do we work?",
    "intro": "We don't just build reports or make integrations. We give businesses end to end service from measurement strategy to actionable insights.",
    "stages": [
      {
        "title": "Define Objectives",
        "description": "Collaborate with businesses to understand and outline their specific goals and objectives."
      },
      {
        "title": "Measurement Strategy",
        "description": "Develop a bespoke measurement strategy that aligns with the business objectives."
      },
      {
        "title": "Implementation",
        "description": "Ensure precise implementation of Google Analytics 4, including event tracking and custom dimensions."
      },
      {
        "title": "Data Integrity",
        "description": "Vigilantly monitor data quality and ensure accuracy in the analytics setup."
      },
      {
        "title": "Custom Reporting",
        "description": "Design and generate custom reports and dashboards tailored to business needs."
      },
      {
        "title": "Actionable Insights",
        "description": "Dive deep into the data to uncover insights that drive decision-making."
      },
      {
        "title": "Optimization",
        "description": "Provide recommendations for website and marketing campaign optimizations."
      },
      {
        "title": "Training",
        "description": "Equip teams with the knowledge to utilize Google Analytics 4 to its fullest."
      },
      {
        "title": "Continuous Support",
        "description": "Offer ongoing support and consultancy to ensure sustained success in all analytics endeavors."
      }
    ],
    "conclusion": "The choice is yours: you can either work with us holistically, or you can customize your selection by picking only what you need."
  },
  "ga4": {
    "title": "What is GA4?",
    "description": "<p>GA4 (Google Analytics 4) is a tool developed by Google for understanding user behavior and performance.</p><p>Google Analytics has been a web analytics standard in the last decade because of its free availability and comprehensive reporting features.</p><p>One of the differentiators of GA4 is seamless integration with various Google tools, like Search Console, Google Ads, Firebase, Play Console, Admob, and BigQuery. This makes it an essential choice for businesses looking to make data-driven decisions.</p>"
  },
  "ga4_help": {
    "title": "How can GA4 help me?",
    "intro": "<p>With GA4, you can optimize marketing strategies, improve user experiences, and make data-driven decisions.</p><p>Here are some of the key benefits</p>",
    "benefits": [
      {
        "title": "Track User Engagement",
        "description": "Understand how users engage with your website or app, including which pages or features they interact with the most, and use this data to enhance user experiences."
      },
      {
        "title": "Audience Segmentation",
        "description": "Utilize audience segmentation to tailor your marketing efforts for different user groups, increasing the effectiveness of your campaigns."
      },
      {
        "title": "Content Performance Analysis",
        "description": "Evaluate the performance of your content, such as blog posts or product pages, by tracking metrics like page views, time on page, and bounce rates."
      },
      {
        "title": "E-commerce Tracking",
        "description": "If you run an online store, GA4 helps you track and optimize the entire customer journey, from product views to checkouts and successful purchases."
      },
      {
        "title": "Mobile App Analytics",
        "description": "For businesses with mobile apps, GA4 offers insights into user behavior within the app, helping you refine app features and monetization strategies."
      },
      {
        "title": "Custom Event Tracking",
        "description": "Create custom events and events-based goals to track specific user actions, such as video views, downloads, or form submissions."
      },
      {
        "title": "User Retention Analysis",
        "description": "Understand user retention and churn rates, enabling you to implement strategies that boost user loyalty and reduce churn."
      },
      {
        "title": "Real-time Analytics",
        "description": "Monitor your website or app's performance in real-time, allowing you to react swiftly to changing trends or issues."
      },
      {
        "title": "Attribution Modeling",
        "description": "Analyze how different touchpoints contribute to conversions, aiding in the optimization of marketing channels and budget allocation."
      },
      {
        "title": "Cross-platform Tracking",
        "description": "Gain insights into how users switch between devices, helping you provide a seamless experience across various platforms."
      }
    ]
  }
}

export default defaultContent;
