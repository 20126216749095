import { html } from 'htm/preact';
import * as style from './ServiceCard.module.css';
import SecondaryButton from '../../components/buttons/SecondaryButton';

function ServiceCard(props) {
  const { title, desc, cta, ctaUrl, img, color, borderColor } = props;

  const jpgFallback = img.replace('.webp', '.jpg');

  return html`
        <div class=${style.container} style="border-color: ${borderColor}">
          <picture>
            <source srcset="${img}" type="image/webp" />
            <img src="${jpgFallback}" alt="${title}" width="500" height="242" loading="lazy" />
          </picture>
          <h4 class=${style.h4}>${title}</h4>
          <div class=${style.p} dangerouslySetInnerHTML=${{ __html: desc }}></div>
          <${SecondaryButton} text=${cta} href=${ctaUrl} color=${color} />
        </div>
    `;
}

export default ServiceCard;
