import { html } from 'htm/preact';
import { useContext } from 'preact/hooks';
import { LanguageContext } from '../contexts/languageContext';
import enFlag from '../assets/images/uk.png';
import trFlag from '../assets/images/tr.png';
import routes from './Routes';
import { useLocation } from 'preact-iso';

function LanguageSwitcher() {
	const location = useLocation();
  const { language, setLocale } = useContext(LanguageContext);
  const { en: enRoutes, tr: trRoutes } = routes;

  const getTargetPath = (currentPath, currentLang) => {
	const targetRoutes = currentLang === 'en' ? trRoutes : enRoutes;
	const sourceRoutes = currentLang === 'en' ? enRoutes : trRoutes;
	const sourcePath = Object.keys(sourceRoutes).find(key => sourceRoutes[key].url === currentPath);
	return sourcePath ? targetRoutes[sourcePath].url : null;
  };

  const toggleLanguage = () => {
	const currentPath = window.location.pathname;
	const targetPath = getTargetPath(currentPath, language);

	if (targetPath) {
	  location.route(targetPath);
	}

	setLocale(language === 'en' ? 'tr-TR' : 'en-US');
  };

  const getFlagImage = (currentLang) => currentLang === 'en' ? trFlag : enFlag;

  return html`
	<div onClick=${toggleLanguage} style="cursor: pointer; padding: 10px;">
	  <img src=${getFlagImage(language)} alt="Switch Language" style="width: 40px; height: 40px;" />
	</div>
  `;
}

export default LanguageSwitcher;