import { html } from 'htm/preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import * as style from './GenericHero.module.css';
import HeroButton from '../components/buttons/HeroButton';

function GenericHero(props) {
  const { h1, h2, imgSrc, backgroundImage, url, cta, placeholder, heroWidth, heroHeight } = props;
  const [imageLoaded, setImageLoaded] = useState(false);

  const pngFallback = imgSrc.replace('.webp', '.png');

  useEffect(() => {
    let scrollTimeout;

    const pauseAnimation = () => {
      const animationElement = document.querySelector(`.${style.animation}`);
      if (animationElement) {
        animationElement.style.animationPlayState = "paused";
      }
    };

    const resumeAnimation = () => {
      const animationElement = document.querySelector(`.${style.animation}`);
      if (animationElement && window.scrollY < 200) {
        animationElement.style.animationPlayState = "running";
      }
    };

    const handleScroll = () => {
      clearTimeout(scrollTimeout);  // Clear any existing timeout.
      pauseAnimation();  // Pause the animation.

      // Set a timeout to resume animation after 300ms if no further scroll detected.
      scrollTimeout = setTimeout(resumeAnimation, 2000);
    };

    setTimeout(resumeAnimation, 3000);

    document.body.addEventListener('touchstart', handleScroll);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.body.removeEventListener('touchstart', handleScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return html`
        <div class="${style.container} ${style.animation}" style="animation-play-state: paused; background-image: ${backgroundImage}">
            <div>
                <div class=${style.content}>
                    <h1 class=${style.h1}>${h1}</h1>
                    <h2 class=${style.h2}>${h2}</h2>
                    <${HeroButton} text=${cta} href=${url} />
                </div>
                <div class=${style.hero}>
                    <picture>
                        <source srcset="${imgSrc}" type="image/webp" />
                        <img src="${pngFallback}" width=${heroWidth} height=${heroHeight} class="${style.shadow}" alt="Hero image" onload=${() => setImageLoaded(true)} 
                        style="filter: blur(${imageLoaded ? 0 : 20}px)" />
                    </picture>
                    <picture>
                        <source srcset="${imageLoaded ? imgSrc : placeholder}" type="image/webp" />
                        <img src="${pngFallback}" width=${heroWidth} height=${heroHeight} alt="Hero image"
                        style="filter: blur(${imageLoaded ? 0 : 20}px)" />
                    </picture>
                </div>
            </div>
        </div>
    `;
}

export default GenericHero;
