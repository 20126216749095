.container {
  background-color: #fff;
  border-radius: 30px;
  border: 5px solid #6ceeff;
  text-align: left;
  min-width: 80vw;
  max-width: 400px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  max-width: 300px;
  overflow: hidden;
}

.container img {
  max-width: 100%;
  height: auto;
}

.h4 {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 10px;
  flex: 0 0 auto;
  padding: 20px 20px 0;
  white-space: initial;
}

.p {
  font-size: 0.9em;
  color: #333;
  padding: 0 20px;
}

.container a {
  margin: auto 20px 20px 20px;
  text-align: center;
}

.icon {
  font-size: 3.5em;
  margin-bottom: 0.3em;
}

@media only screen and (min-width: 400px) {
  .container {
    min-width: 300px;
  } 
}

@media only screen and (min-width: 1024px) {
  .container {
    min-width: auto;
    font-size: 1.2vw;
  } 
}

@media only screen and (min-width: 1280px) {
  .container {
    min-width: auto;
    font-size: 0.9em;
  } 
}
