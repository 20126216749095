.button {
  text-decoration: none;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 10px 30px 12px;
  border-radius: 40px;
  font-weight: 700;
  font-size: 1.5em;
  display: inline-block;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

.button:hover {
  background-color: var(--buttonHoverBg);
  color: var(--buttonHoverTextColor) !important;
}
