.container {
  padding: 40px 0 0;
  margin-top: -20px;
  clip-path: polygon(26% 20px, 100% 0, 100% 100%, 0 100%, 0 0);
  background: #fff;
}

.h3 {
  text-align: center;
}
