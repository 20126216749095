import { html } from 'htm/preact';
import * as style from './ContentImageBlock.module.css';

function ContentImageBlock(props) {
  const { number, title, textHtml, imageWebp, imageBigWebp, imageFallbackPng } = props;
  
  return html`
  <div class=${style.container}>
    <div>
      <h5>
        ${number !== undefined ? html`<span>${number}</span>` : ''}
        ${title}
      </h5>
      ${textHtml}
    </div>
    <img src=${imageFallbackPng} srcset="${imageWebp} 300w, ${imageBigWebp} 1080w" width="300" height="300" alt="Discovery & Strategy" loading="lazy" />
  </div>
`;
}

export default ContentImageBlock;
