import { html } from 'htm/preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import { LanguageContext } from '../contexts/languageContext';
import * as style from './Testimonials.module.css';

import liz from '../assets/images/liz.jpg';
import sarah from '../assets/images/sarah.jpg';
import david from '../assets/images/david.jpg';
import eddie from '../assets/images/eddie.jpg';

import marty from '../assets/images/marty.jpg';
import steve from '../assets/images/steve.jpg';
import michal from '../assets/images/michal.jpg';
import alex from '../assets/images/alex.jpg';

function Testimonials(props) {
  const { language } = useContext(LanguageContext);
  const { context, isWhite } = props;

  const allTestimonials = {
    "design": {
      "en": [
        ["Digital Peax's designers have an eye for detail. The website they crafted for us beautifully captures our brand, engaging our audience like never before. So glad we chose them.", david, "David L."],
        ["The whole experience with Digital Peax was smooth from start to finish. From initial design concepts to the website going live, they've been on point - always ready to adapt and infuse creativity.", liz, "Liz Strong"],
        ["Digital Peax's grasp on UI/UX design shines through in our website. It's clear they know what they're doing, and it's made a world of difference for our online presence.", sarah, "Sarah Lauren"],
        ["There's something about the way Digital Peax approaches design. Our website is not just good-looking but also intuitive and user-friendly. Big thumbs up to their team.", eddie, "Eddie"]
      ],
      "tr": [
        ["Digital Peax'in tasarımcıları detaylara dikkat ediyor. Bizim için oluşturdukları web sitesi markamızı güzel bir şekilde yansıtıyor ve izleyicimizi her zamankinden daha fazla etkiliyor. Onları seçtiğimiz için çok mutluyuz.", david, "David L."],
        ["Digital Peax ile olan tüm deneyim baştan sona sorunsuzdu. İlk tasarım konseptlerinden web sitesinin yayına girmesine kadar her zaman uyum sağladılar ve yaratıcılığı infüze etmeye hazırdılar.", liz, "Liz Strong"],
        ["Digital Peax'in UI/UX tasarımına hakimiyeti web sitemizde parlıyor. Ne yaptıklarını bildikleri açık ve bu, çevrimiçi varlığımız için büyük bir fark yarattı.", sarah, "Sarah Lauren"],
        ["Digital Peax'in tasarıma yaklaşımında bir şey var. Web sitemiz sadece güzel görünmekle kalmıyor, aynı zamanda sezgisel ve kullanıcı dostu. Ekibine büyük alkış.", eddie, "Eddie"]
      ]
    },
    "development": {
      "en": [
        ["Working with Digital Peax was a breeze. They approached our project with such professionalism and delivered top-notch results. Really impressed.", marty, "Marty F."],
        ["I've collaborated with several app developers before, but the expertise Digital Peax brings to the table is something else. They created a mobile app for us that's not only visually appealing but also user-friendly. Kudos to the team.", steve, "Steve Taylor"],
        ["Our experience with Digital Peax was nothing short of excellent. Their team really knows their stuff when it comes to mobile app development. We couldn't be happier with the app they created for us.", michal, "Michal Honas"],
        ["What stands out about Digital Peax is their commitment to quality and compatibility. The app they made for us works seamlessly on both iOS and Android. It's clear they're professionals in this field.", alex, "Alex Payton"]
      ],
      "tr": [
        ["Digital Peax ile çalışmak çok kolaydı. Projemize büyük bir profesyonellikle yaklaştılar ve üst düzey sonuçlar verdiler. Gerçekten etkilendim.", marty, "Marty F."],
        ["Daha önce birkaç uygulama geliştirici ile işbirliği yaptım, ancak Digital Peax'in masaya getirdiği uzmanlık başka bir şey. Bizim için sadece görsel olarak çekici değil, aynı zamanda kullanıcı dostu bir mobil uygulama oluşturdular. Ekibe tebrikler.", steve, "Steve Taylor"],
        ["Digital Peax ile olan deneyimimiz mükemmeldi. Mobil uygulama geliştirme konusunda gerçekten ne yaptıklarını biliyorlar. Bizim için oluşturdukları uygulamadan daha mutlu olamazdık.", michal, "Michal Honas"],
        ["Digital Peax'in öne çıkan özelliği kalite ve uyumluluğa olan bağlılıklarıdır. Bizim için yaptıkları uygulama hem iOS hem de Android'de sorunsuz çalışıyor. Bu alanda profesyonel oldukları açık.", alex, "Alex Payton"]
      ]
    },
    "analytics": {
      "en": [
        ["Digital Peax revolutionized our data insights with their exceptional expertise in Google Analytics 4. Thanks to their guidance, we now navigate our digital landscape with precision.", sarah, "Sarah Lauren"],
        ["Working with Digital Peax for our GA4 setup was a game-changer. Their insights transformed our marketing strategy, leading to remarkable growth. We're grateful for their data mastery.", david, "David L."],
        ["Digital Peax exceeded our expectations with their GA4 services. They've uncovered valuable data insights that have given us a significant competitive edge. Their expertise is truly remarkable.", eddie, "Eddie"],
        ["We entrusted Digital Peax with our GA4 implementation, and they delivered beyond measure. Their expertise in analytics empowered us to make data-driven decisions, resulting in a substantial ROI.", steve, "Steve Taylor"]
      ],
      "tr": [
        ["Digital Peax, Google Analytics 4 konusundaki olağanüstü uzmanlıklarıyla veri içgörülerimizi devrim niteliğinde değiştirdi. Onların rehberliği sayesinde dijital ortamımızda hassasiyetle gezinmekteyiz.", sarah, "Sarah Lauren"],
        ["GA4 kurulumumuz için Digital Peax ile çalışmak bir dönüm noktasıydı. İçgörüleri pazarlama stratejimizi dönüştürdü ve dikkate değer bir büyümeye yol açtı. Veri ustalıkları için minnettarız.", david, "David L."],
        ["Digital Peax, GA4 hizmetleriyle beklentilerimizi aştı. Bize önemli bir rekabet avantajı sağlayan değerli veri içgörüleri ortaya çıkardılar. Uzmanlıkları gerçekten dikkate değer.", eddie, "Eddie"],
        ["GA4 uygulamamız için Digital Peax'e güvendik ve beklentilerin ötesinde teslim ettiler. Analitik konusundaki uzmanlıkları, veri odaklı kararlar almamızı sağladı ve önemli bir yatırım getirisi sağladı.", steve, "Steve Taylor"]
      ]
    }
  };

  const addWhiteClass = isWhite !== undefined ? true : false;

  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  let testimonials;

  if (context && allTestimonials.hasOwnProperty(context)) {
    testimonials = allTestimonials[context][language] || allTestimonials[context]["en"];
  } else {
    // If context is undefined or doesn't match any key, return all items
    testimonials = Object.values(allTestimonials).map(category => category[language] || category["en"]).flat();
  }

  useEffect(() => {
    const fadeDuration = 1000; // 1 second
    const displayDuration = 8000; // 4 seconds

    const handleFade = () => {
        setFadeIn(false);

        setTimeout(() => {
            setTestimonialIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
            setFadeIn(true);
        }, fadeDuration);
    };

    const interval = setInterval(handleFade, fadeDuration + displayDuration);

    return () => clearInterval(interval);
}, [context]);

  const testimonial = testimonials[testimonialIndex];

  return html`
  <div class="${style.testimonial} ${addWhiteClass && style.testimonial_white}">
      <div class="content ${style.content} ${fadeIn ? style['fade-in'] : ''}">
        <img src=${testimonial[1]} width="150" height="150" alt="${testimonial[2]}" loading="lazy" />
        <div>
          <q>${testimonial[0]}</q>
          <span class=${style.author}>-${testimonial[2]}-</span>
        </div>
      </div>
    </div>
        `;
}

export default Testimonials;
