import { html } from 'htm/preact';
import { useContext } from 'preact/hooks';
import { LanguageContext } from '../../contexts/languageContext';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import * as style from './Success.module.css';
import routes from '../../components/Routes';

function Success() {
  const { t, language } = useContext(LanguageContext);

  return html`
        <div class="${style.container} ${style.animation}">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="150" viewBox="0 0 64 64">
          <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" fill="#ffffff"></path>
        </svg>
          <h1>${t('thankYou')}</h1>
          <h2>${t('requestReceived')}</h2>
          <${SecondaryButton} text="${t('homepage')}" href="${routes[language].home.url}" color="#ffffff" />
        </div>
    `;
}

export default Success;
