import { html } from 'htm/preact';
import * as style from './Header.module.css';
import { useContext, useState, useEffect } from 'preact/hooks';
import { LanguageContext } from '../contexts/languageContext';
import LanguageSwitcher from './LanguageSwitcher';
import logo from '../assets/images/dp_logo.png';
import Nav from './Nav';

function Header() {
  const { t } = useContext(LanguageContext);

  const [headerOpacity, setHeaderOpacity] = useState(0);
  const [textColor, setTextColor] = useState("white");
  const [logoScale, setLogoScale] = useState(0.85);
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      
      if(document.body.offsetHeight-window.innerHeight < 100) {
        setHeaderOpacity(0);
        setTextColor("#fff");
        setLogoScale(0.85);
        return;
      };

      const fullTransitionScroll = 200;

      const headerOpacityValue = Math.min(window.scrollY / fullTransitionScroll, 1);
      setHeaderOpacity(headerOpacityValue);

      const textColorValue = Math.min(window.scrollY / fullTransitionScroll, 1);
      const interpolatedColor = interpolateColor([255, 255, 255], [68, 68, 68], textColorValue);
      setTextColor(interpolatedColor);

      const logoScaleValue = 0.85 + Math.min(0.17 * (window.scrollY) / fullTransitionScroll, 0.17);
      setLogoScale(logoScaleValue);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function toggleMenu(){
    setMenuVisible(!menuVisible);
  };


  const { language } = useContext(LanguageContext);

  return html`
    <header class=${style.header} style=${`box-shadow: 5px 0 10px rgba(0, 0, 0, ${headerOpacity*0.15}); transform: translateY(${menuVisible ? -105 : 0}%); transition: transform .3s ${menuVisible ? 0 : 0.3}s, opacity .3s 0s;
    background-color: rgba(255, 255, 255, ${headerOpacity})
    `}><div>
      <a class=${style.logoWrapper} href="/${language === 'tr' ? 'tr' : ''}">
        <div class=${style.logoBg}>
          <img class=${style.logo} alt="Digital Peax Logo" src=${logo} width="40" height="40" style="scale: ${logoScale}" />
        </div>
        <span style="color: ${textColor}">Digital Peax</span>
      </a>
      <!--<div>
        <${LanguageSwitcher} />
      </div>-->
      <button class=${style.menu} onClick=${toggleMenu} aria-label="Menu">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 72 72">
            <path d="M56 48c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 48 54.798 48 56 48zM56 32c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 32 54.798 32 56 32zM56 16c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 16 54.798 16 56 16z" fill="${textColor}"></path>
        </svg>
      </button>
      </div>
    </header>
    <${Nav} visible=${menuVisible} toggleMenu=${toggleMenu} />
  `;
}

function interpolateColor(startRGB, endRGB, factor) {
  const [r1, g1, b1] = startRGB;
  const [r2, g2, b2] = endRGB;

  const r = Math.round(r1 + factor * (r2 - r1));
  const g = Math.round(g1 + factor * (g2 - g1));
  const b = Math.round(b1 + factor * (b2 - b1));

  return `rgb(${r}, ${g}, ${b})`;
}


export default Header;
