.button {
  margin: 0 auto;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(40deg, #ffb52b, #ff5b32);
  font-size: 1.5em;
  padding: 10px 2em 11px;
  display: inline-block;
  border-radius: 60px;
  border: 3px solid #ff5b32;
  margin-top: 15px;
  font-weight: 700;
}

.button:hover {
  background: linear-gradient(40deg, #db991d, #ca4220);
}

@media only screen and (min-width: 1024px) {
  .button {
    margin: 0;
    text-decoration: none;
    color: #ffffff;
    border: 2px solid #ffffff;
    padding: 10px 30px 12px;
    border-radius: 40px;
    font-weight: 700;
    font-size: 1.5em;
    display: inline-block;
    cursor: pointer;
    background: none;
  }

  .button:hover {
    background: linear-gradient(40deg, #ffb52b, #ff5b32);
    color: #fff;
  }
}