import { html } from 'htm/preact';
import * as style from './Contact.module.css';
import { useContext } from 'preact/hooks';
import { LanguageContext } from '../../contexts/languageContext';
import ContactUs from '../../components/ContactUs';

function Contact() {
  const { t } = useContext(LanguageContext);

  return html`
  <div class=${style.container} id="quote">
    <${ContactUs} />
    </div>
  `;
}

export default Contact;
