.hero_wrapper {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hero_content {
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.hero_content h1 {
  margin: 0;
  font-family: Merriweather, serif;
}

.hero_content p {
  font-size: 1.2em;
  padding: 10px 30px;
}

.hero {
  width: 100%;
  height: auto;
  background-color: #eafcff;
  padding: 50px 0 0;
}

.teamWrapper {
  padding: 0 0 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-self: center;
}

.bounce {
  width: auto;
  height: 20vh;
  align-self: center;
  -moz-animation: bounce 4s infinite;
  -webkit-animation: bounce 4s infinite;
  animation: bounce 4s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@media only screen and (min-width: 1024px) {
  .container {
    min-height: 300px;
  }
  .hero {
    height: 80%;
    max-height: 700px;
    max-width: 50vw;
    padding: 0;
    border-radius: 50%;
  }
  .hero_wrapper {
    padding: 0 20px;
    height: 100vh;
    min-height: auto;
    flex-direction: row-reverse;
    justify-content: center;
    text-align: left;
    column-gap: 50px;
    align-items: center;
    font-size: 25px;
    max-width: 1280px;
    margin: 0 auto
  }
  .hero_content {
    justify-content: center;
    position: static;
    flex: 1;
    padding: 0;
  }
  .hero_wrapper p {
    padding: 10px 0;
  }
  .teamWrapper {
    width: 1200px;
    margin: 20px auto 0;
    display: block;
  }

  .bounce {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0) translateX(-50%);
    }
    40% {
      transform: translateY(-30px) translateX(-50%);
    }
    60% {
      transform: translateY(-15px) translateX(-50%);
    }
  }
}
