import { h, createContext } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { path } from 'preact-iso';
import EN from '../translations/en.json';
import TR from '../translations/tr.json';

export const LanguageContext = createContext();

const translations = {
  en: EN,
  tr: TR
};

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState('en');
  const [locale, setLocale] = useState('en-US');

  useEffect(() => {
    const lang = window.location.pathname.startsWith('/tr') ? 'tr' : 'en';
    setLocale(lang === 'tr' ? 'tr-TR' : 'en-US');
  }, [location]);

  function t(key) {
    return translations[language][key] || key;
  }

  useEffect(() => {
    setLanguage(locale === "en-US" ? "en" : "tr");
  }, [locale]);

  return h(LanguageContext.Provider, { value: { language, setLanguage, t, locale, setLocale } }, children);
}
