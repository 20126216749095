const defaultContent = {
  "title": "Proven digital mastery",
  "experience": "With 10+ years of experience, Digital Peax delivers proven expertise in web and mobile app development.",
  "how_we_work": {
    "title" : "How do we work?",
    "intro": "From understanding your vision to ensuring its flawless execution, our process unfolds in well-defined stages, guaranteeing the success of your digital endeavors.",
    "stages": [
      {
        "title": "Discovery & Strategy",
        "description": "Before we dive into development, we invest time to understand your vision, goals, and target audience. This phase is all about aligning our strategies with your objectives, ensuring a foundation for success.",
        "related_services": "Analytics"
      },
      {
        "title": "Design & Prototyping",
        "description": "Once we have a clear roadmap, our designers craft intuitive and user-friendly interfaces. With prototypes, you get a tangible feel of your product before actual development, ensuring it looks and feels just right.",
        "related_services": "Design"
      },
      {
        "title": "Development & Testing",
        "description": "With a design in hand, our skilled developers bring your digital solution to life using cutting-edge open-source technologies. Every feature undergoes rigorous testing, ensuring your platform is robust, reliable, and ready for your users."
      },
      {
        "title": "Launch & Post-launch Support",
        "description": "When everything's set, we guide you through a smooth launch. But our relationship doesn't end there. We're here for post-launch support, updates, and any refinements, ensuring your platform continues to excel in the ever-evolving digital landscape."
      }
    ]
  },
  "technologies": {
    "description": "We embrace the power of open-source technologies and scalability of cloud solutions to build dynamic and responsive web and mobile applications.",
    "conclusion": "Trust in our expertise to choose the right technology that aligns with your vision, delivering solutions that captivate and resonate."
  }
}

export default defaultContent;
