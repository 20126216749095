import { html } from 'htm/preact';
import * as style from './Footer.module.css';
import { useContext, useState, useEffect } from 'preact/hooks';
import { LanguageContext } from '../contexts/languageContext';
import logo from '../assets/images/dp_logo.png';
import { fetchContent } from '../graphqlClient';
import routes from './Routes';

function getHomepageQuery(locale) {
  return `
    query footerEntryQuery {
      footer(id: "58FkcTO48jsg0RgCA3Yn7t", locale: "${locale}") {
        aboutTitle
        aboutDescription
        address
      }
    }
  `;
}

function Footer() {
  const [aboutTitle, setAboutTitle] = useState('About');
  const [aboutDescription, setAboutDescription] = useState('Digital Peax is a digital apps development and design agency with a special proficiency in analytics and optimizations.');
  const [address, setAddress] = useState('Digital Peax OU<br />Sepapaja tn 6, 15551 Tallinn<br />Estonia');
  const { t, locale, language } = useContext(LanguageContext);

  const year = new Date().getFullYear();

  useEffect(() => {
    const getHomepageContent = async () => {
    try {
      const data = await fetchContent(getHomepageQuery(locale));
      setAboutTitle(data.data.footer.aboutTitle);
      setAboutDescription(data.data.footer.aboutDescription);
      setAddress(data.data.footer.address);
    } catch (error) {
      console.error(error);
    }
    };

    getHomepageContent();
  }, [locale]);

  return html`
    <footer class=${style.footer}>
      <div class=${style.content}>
        <div>
        <div class=${style.title}>Links</div>
        <div class=${style.links}>
          <a href="${routes[language].home.url}">${routes[language].home.name}</a>
          <a href="${routes[language].team.url}">${routes[language].team.name}</a>
          <a href="${routes[language].development.url}">${routes[language].development.name}</a>
          <a href="${routes[language].design.url}">${routes[language].design.name}</a>
          <a href="${routes[language].analytics.url}">${routes[language].analytics.name}</a>
          <a href="#quote">${t("contactUs")}</a>
        </div>
        </div>
        <div>
        <div class=${style.title}>${aboutTitle}</div>
        <p>
          ${aboutDescription}
        </p>
          <div dangerouslySetInnerHTML=${{ __html: address }}></div>
        </div>
      </div>
      <div class=${style.logo}>
        <img src=${logo} width="40" height="40" alt="Digital Peax logo" />
        <p>Digital Peax | © ${year}</p>
      </div>
    </footer>
  `;
}

export default Footer;
